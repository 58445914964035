import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { FileInput } from './FileInput/FileInput'
import { form, form_title, form_rowfields, form_textarea, form_btn } from './style.module.scss'

const Application = ({ onSubmit }) => {
  const [validated, setValidated] = React.useState(false)

  const handleSubmit = (event) => {
    const form = event.currentTarget
    const isValid = !!form.checkValidity()

    if (!isValid) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)

    if (isValid) {
      onSubmit && onSubmit(event)
    }
  }

  return (
    <Form className={form} noValidate validated={validated} onSubmit={handleSubmit}>
      <p className={form_title}>Application</p>
      <div className={form_rowfields}>
        <Form.Group>
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" name="name" required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required />
        </Form.Group>
      </div>
      <Form.Group className={form_textarea}>
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" type="text" name="message" required />
      </Form.Group>
      <FileInput id="file" placeholder="Attach Resume" validated={validated} required />
      <Button className={form_btn} type="submit">
        Send
      </Button>
    </Form>
  )
}

export default Application
