import React, { useEffect, useState } from 'react'
import { FileList } from './FileList'
import { Input } from './Input'
import * as s from './FileInput.module.scss'

const filterFile = (file) => (files) => {
  return files.filter((item) => item !== file)
}

export const FileInput = ({ onChange, ...props }) => {
  const [files, setFiles] = useState([])

  useEffect(() => {
    onChange && onChange(files)
  }, [files, onChange])

  const onChangeHandler = (e) => {
    setFiles([...e.target.files])
  }

  const onDeleteHandler = (file) => {
    setFiles(filterFile(file))
  }

  return (
    <div className={s.root}>
      {!!files.length && <FileList files={files} onDelete={onDeleteHandler} />}
      {!files.length && <Input {...props} onChange={onChangeHandler} />}
    </div>
  )
}
