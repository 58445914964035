import React from 'react'
import cx from 'classnames'
import { Form } from 'react-bootstrap'
import Icon from '../../Icon'
import * as s from './Input.module.scss'

export const Input = ({ id, placeholder, validated, ...props }) => {
  return (
    <div className={cx(s.root, { [s.validated]: validated })}>
      <div className={s.border}>
        <Icon name="border" size="100%" />
      </div>
      <Form.Control id={id} type="file" {...props} />
      <label htmlFor={id}>
        <Icon name="plus" size={24} />
        {placeholder}
      </label>
    </div>
  )
}
