import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DATA from './constants'
import { hero, hero_title, hero_team, hero_item, hero_pic, hero_position, hero_name } from './style.module.scss'

export const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            resize {
              originalName
            }
            gatsbyImageData(layout: CONSTRAINED, width: 262, height: 300, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)
  return (
    <section className={hero}>
      <h1 className={hero_title}>Let’s work together!</h1>
      <ul className={hero_team}>
        {DATA.map(({ name, position }) => {
          const node = data.allImageSharp.edges.find(
            ({
              node: {
                resize: { originalName },
              },
            }) => originalName.includes(name.split(' ')[0])
          )
          return (
            <li key={name} className={hero_item}>
              <div className={hero_pic}>
                <GatsbyImage image={node.node.gatsbyImageData} alt={name} style={{ display: 'block' }} />
              </div>
              <p className={hero_position}>{position}</p>
              <p className={hero_name}>{name}</p>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
