import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Icon from '~components/Icon'
import Application from '~components/Application'
import building from './img/building.svg'
import {
  vacancies,
  vacancies_heading,
  vacancies_inner,
  vacancies_list,
  vacancies_item,
  vacancies_position,
  vacancies_label,
  vacancies_empty,
  vacancies_descr,
} from './style.module.scss'

export const Vacancies = () => {
  const data = useStaticQuery(graphql`
    {
      allCareersJson {
        edges {
          node {
            header
            employment
            address
            slug
          }
        }
      }
    }
  `)

  const hasVacancies = !!data.allCareersJson.edges?.length

  return (
    <section className={vacancies}>
      <h2 className={vacancies_heading}>Careers</h2>
      <div className={vacancies_inner}>
        {hasVacancies && (
          <ul className={vacancies_list}>
            {data.allCareersJson.edges.map(({ node: { header, employment, address, slug } }) => (
              <li key={header}>
                <Link className={vacancies_item} to={slug}>
                  <span className={vacancies_position}>{header}</span>
                  {employment && (
                    <span className={vacancies_label}>
                      <Icon name="case" size="20" />
                      {employment}
                    </span>
                  )}
                  {address && (
                    <span className={vacancies_label}>
                      <Icon name="mark" size="20" />
                      {address}
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        )}
        {!hasVacancies && (
          <div className={vacancies_empty}>
            <div>
              <img src={building} alt="building" />
            </div>
            <p className={vacancies_descr}>
              There are no vacancies now, But if you feel that you have something to offer - feel free to fill the
              application.
            </p>
          </div>
        )}
        <Application />
      </div>
    </section>
  )
}
