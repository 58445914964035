import React from 'react'
import * as s from './FileList.module.scss'
import { FileItem } from './FileItem'

export const FileList = ({ files, onDelete }) => {
  return (
    <div className={s.root}>
      {files.map((file) => (
        <FileItem key={file.name} file={file} onDelete={onDelete} />
      ))}
    </div>
  )
}
