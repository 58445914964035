import React from 'react'
import filesize from 'filesize'
import Icon from '../../Icon'
import * as s from './FileItem.module.scss'

export const FileItem = ({ file, onDelete }) => {
  const onDelteHandler = () => {
    onDelete(file)
  }

  return (
    <div className={s.root}>
      <div>
        <Icon name="doc" size={48} />
      </div>
      <div>
        <p className={s.name}>{file.name}</p>
        <p className={s.size}>{filesize(file.size)}</p>
      </div>
      <div>
        <button type="button" className={s.deleteBtn} onClick={onDelteHandler}>
          <Icon name="close" size={32} />
        </button>
      </div>
    </div>
  )
}
